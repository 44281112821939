<template>
  <div>
    <el-row>
      <el-button v-throttle type="primary" @click="handleAddTaskBtn"
        >发布新任务</el-button
      >
    </el-row>
    <el-row style="margin-top: 20px">
      <el-form inline :model="taskManageCondition">
        <!-- 编号/名称 -->
        <el-form-item
          class="formItemBoxStyle"
          label="名称/编号"
          prop="planCodeName"
        >
          <el-input
            style="width: 200px"
            v-model="taskManageCondition.planCodeName"
            placeholder="请输入"
            @blur="handleQueryTaskTableData"
          />
        </el-form-item>
        <!-- 任务状态 -->
        <el-form-item class="formItemBoxStyle" label="状态" prop="status">
          <el-select
            style="width: 200px"
            v-model="taskManageCondition.status"
            @change="handleFormSelectTaskStatus"
          >
            <el-option label="全部" value=""></el-option>
            <el-option label="未开始" :value="2"></el-option>
            <el-option label="进行中" :value="3"></el-option>
            <el-option label="已结束" :value="4"></el-option>
          </el-select>
        </el-form-item>
        <!-- 日期选择 -->
        <el-form-item class="formItemBoxStyle" label="日期">
          <div class="block">
            <el-date-picker
              v-model="queryTaskManageTime"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="queryTaskManageDateChange"
              :default-time="['00:00:00', '23:59:59']"
            ></el-date-picker>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button v-throttle @click="handleResetTaskTableData"
            >重置</el-button
          >
          <el-button v-throttle type="primary" @click="handleQueryTaskTableData"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </el-row>
    <!-- 任务状态一栏 -->
    <div style="display: flex">
      <div
        class="taskStatusItem"
        v-for="(item, i) in taskStatusList"
        :key="item.name"
        :class="item.isSelect ? 'selected' : ''"
        @click="handleSelectTaskStatus(i)"
      >
        <span>{{ item.name }}（{{ item.num }}）</span>
      </div>
    </div>
    <el-row style="margin-top: 20px">
      <el-table
        :header-cell-style="{
          'text-align': 'center',
          backgroundColor: '#dfe6ec',
        }"
        :cell-style="{ 'text-align': 'center' }"
        :data="materialCheckPlanList"
      >
        <el-table-column
          label="任务编号"
          prop="planCode"
          width="150"
        ></el-table-column>
        <el-table-column label="任务类型" prop="taskType" width="180">
          <span>实验仪器耗材盘点</span>
        </el-table-column>
        <el-table-column label="任务名称" prop="planName">
          <template slot-scope="scope">
            <span
              style="color: #00f; text-decoration: underline; cursor: pointer"
              @click="handleEditTaskBtn(scope.row, 2)"
              >{{ scope.row.planName }}</span
            >
          </template>
        </el-table-column>
        <el-table-column
          label="任务开始时间"
          prop="startTime"
          width="150"
          :formatter="customFormatter"
        ></el-table-column>
        <el-table-column
          label="任务结束时间"
          prop="endTime"
          width="150"
          :formatter="customFormatter"
        ></el-table-column>
        <el-table-column label="执行学校" prop="planSchoolRelationList">
          <template slot-scope="scope">
            <span>{{
              handleSchoolTextFun(scope.row.planSchoolRelationList)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="状态"
          prop="excuteStatus"
          width="100"
          :formatter="customFormatter"
        >
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <span
              v-if="scope.row.excuteStatus == 0"
              class="blueBtn"
              @click="handleCheckFinishSituation(scope.row)"
              >查看完成情况</span
            >
            <span
              v-if="scope.row.excuteStatus == 0"
              class="blueBtn"
              @click="handleEditTaskBtn(scope.row, 0)"
              >编辑</span
            >
            <span
              v-if="scope.row.excuteStatus == 0"
              class="redBtn"
              @click="handleDeleteTaskBtn(scope.row)"
              >删除</span
            >
            <span
              v-if="scope.row.excuteStatus !== 0"
              class="blueBtn1"
              @click="handleCheckFinishSituation(scope.row)"
              >查看完成情况</span
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row style="margin-top: 20px">
      <el-pagination
        @size-change="handleSizeChangeMaterialCheckPlanTableDataMsgPage"
        @current-change="handleCurrentChangeMaterialCheckPlanTableDataMsgPage"
        :current-page="materialCheckPlanTableDataMsgPage.pageIndex"
        :page-sizes="[5, 10, 20, 50, 100, 200]"
        :page-size="materialCheckPlanTableDataMsgPage.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="materialCheckPlanTableDataMsgPage.pageTotal"
      ></el-pagination>
    </el-row>
    <!-- 任务弹框 -->
    <el-dialog
      :title="addOrEditTask === 1 ? '新增任务' : '编辑任务'"
      :visible.sync="taskDialogVisible"
      top="3%"
    >
      <el-form :model="taskForm" ref="taskForm" class="taskForm" :rules="rules">
        <el-form-item label="任务编号" prop="planCode">
          <el-input
            style="width: 85%; margin-left: 15px"
            v-model="taskForm.planCode"
            placeholder="请输入"
            :disabled="addOrEditTask !== 1"
            onkeyup="this.value=this.value.replace(/\s/g,'')"
          ></el-input>
        </el-form-item>
        <el-form-item label="任务类型" prop="taskType">
          <el-select
            style="width: 85%; margin-left: 15px"
            v-model="taskForm.taskType"
            placeholder="请选择"
            disabled
          >
            <el-option label="实验仪器耗材盘点" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="任务名称" prop="planName">
          <el-input
            style="width: 85%; margin-left: 15px"
            v-model="taskForm.planName"
            placeholder="请输入"
            onkeyup="this.value=this.value.replace(/\s/g,'')"
            :disabled="addOrEditTask === 2"
          ></el-input>
        </el-form-item>
        <el-form-item label="任务时间" prop="timeVal">
          <el-date-picker
            v-model="taskForm.timeVal"
            type="daterange"
            range-separator="至"
            style="width: 85%; margin-left: 15px"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :disabled="addOrEditTask === 2"
            @change="queryTaskManageDialogDateChange"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          label="执行学校"
          class="schoolList"
          :class="addOrEditTask == 2 ? 'needColorDeep' : ''"
          prop="schoolList"
        >
          <el-input
            style="width: 85%; margin-left: 15px"
            placeholder="请选择"
            type="textarea"
            :rows="3"
            resize="none"
            disabled
            v-model="taskForm.schoolList"
          />
          <div
            v-if="addOrEditTask !== 2"
            class="selectText"
            @click="handleAddSchoolBtnClick"
          >
            选择
          </div>
        </el-form-item>
        <el-form-item label="详情备注" prop="remark" style="margin-left: 10px">
          <el-input
            style="width: 86%; margin-left: 15px"
            placeholder="请输入"
            type="textarea"
            :rows="3"
            resize="none"
            :disabled="addOrEditTask === 2"
            v-model="taskForm.remark"
            onkeyup="this.value=this.value.replace(/\s/g,'')"
          />
        </el-form-item>
      </el-form>
      <div style="text-align: right" v-if="addOrEditTask !== 2">
        <el-button type="primary" @click="handleSaveTaskBtnClick(1)"
          >保存并发布</el-button
        >
        <el-button @click="handleSaveTaskBtnClick(0)">暂存</el-button>
        <el-button
          @click="
            () => {
              taskDialogVisible = false;
            }
          "
          >取消</el-button
        >
      </div>
      <div style="text-align: right" v-else>
        <el-button
          @click="
            () => {
              taskDialogVisible = false;
            }
          "
          >关闭</el-button
        >
      </div>
    </el-dialog>

    <!-- 添加学校或添加仪器耗材弹框 -->
    <el-dialog
      :visible.sync="addSchoolDialogVisible"
      title="添加执行学校"
      width="800px"
    >
      <div style="display: flex; justify-content: space-between">
        <div class="tag-title">已选择</div>
        <span
          @click="handleRemoveAllAddedSchoolBtnClick"
          style="
            color: red;
            text-decoration: underline;
            cursor: pointer;
            margin-right: 10px;
          "
          >清空所有</span
        >
      </div>

      <div class="tag-content">
        <div
          v-for="item in addSchoolList"
          :key="item.sysOrgSchoolId"
          class="tag-content-item"
        >
          {{ item.name ? item.name : item.sysOrgSchoolName }}
          <div class="tag-item-x" @click="handleRemoveSelectSchoolBtn(item)">
            X
          </div>
        </div>
      </div>
      <div class="tag-title">学校列表</div>
      <el-form inline :model="schoolListQueryCondition">
        <el-form-item
          prop="sysOrgAreaId"
          label="区县："
          class="formItemBoxStyle"
        >
          <el-select
            v-model="schoolListQueryCondition.sysOrgAreaId"
            style="width: 120px"
            @change="fetchSchoolInfo"
          >
            <el-option label="全部" :value="0"></el-option>
            <el-option
              v-for="item in sysOrgAreaList"
              :key="item.orgId"
              :label="item.orgName"
              :value="item.orgId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="name">
          <el-input
            v-model.trim="schoolListQueryCondition.name"
            placeholder="请输入学校名称查询"
            style="width: 180px"
            @input="fetchSchoolInfo"
          />
        </el-form-item>
        <el-form-item prop="name">
          <el-button v-throttle @click="handleResetSchoolBtnClick"
            >重置</el-button
          >
          <el-button
            type="primary"
            v-throttle
            @click="handleQuerySchoolBtnClick"
            >查询</el-button
          >
          <el-button
            type="primary"
            style="margin-left: 95px"
            v-throttle
            @click="handleBatchAddBtnClick"
            >批量添加</el-button
          >
        </el-form-item>
      </el-form>
      <div
        style="height: 300px; width: 100%; overflow: hidden; overflow-y: scroll"
      >
        <el-table
          border
          ref="schoolTableDataList"
          :data="schoolTableDataList"
          :header-cell-style="{
            'text-align': 'center',
            backgroundColor: '#dfe6ec',
          }"
          :cell-style="{ 'text-align': 'center' }"
          @selection-change="handleSelectionChange"
        >
          <el-table-column label="区县" prop="sysOrgAreaName"></el-table-column>
          <el-table-column label="代码" prop="code"></el-table-column>
          <el-table-column label="名称" prop="name"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scoped">
              <div
                v-if="!handleSchoolStatusCheck(scoped.row)"
                style="color: #00f; text-decoration: underline; cursor: pointer"
                @click="handleTableAddSchoolBtnClick(scoped.row)"
              >
                添加
              </div>
              <div v-else style="color: #ccc">已添加</div>
            </template>
          </el-table-column>
          <el-table-column type="selection" width="55"> </el-table-column>
        </el-table>
      </div>

      <div style="margin-top: 20px; text-align: right">
        <el-button v-throttle @click="handleCancelAddSchoolBtnClick"
          >取消</el-button
        >
        <el-button
          type="primary"
          v-throttle
          @click="handleSaveAddSchoolBtnClick"
          >保存</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { isEmpty, formatDate } from "@/utils/util";
import { getParamSchoolApi } from "@/api/mechanism/examSchool.js";
import {
  addCheckPlanApi,
  deleteCheckPlanApi,
  getCheckPlanDetailApi,
  getCheckPlanListApi,
  getCheckPlanStatusApi,
  updateCheckPlanApi,
} from "@/api/taskManage/checkStockPlan.js";
import { getAreaSite } from "@/api/mechanism/examArea.js";
export default {
  data() {
    const checkPlanCode = (rule, value, callback) => {
      let reg = /[^a-z0-9A-Z]/g;
      if (reg.test(this.taskForm.planCode)) {
        callback(new Error("编号只能是数字或字母"));
      } else {
        callback();
      }
    };
    return {
      taskManageCondition: {
        planCodeName: "",
        status: "",
        startTime: "",
        endTime: "",
      },
      queryTaskManageTime: "",
      materialCheckPlanList: [],
      taskStatusList: [
        {
          name: "全部",
          num: 0,
          isSelect: true,
        },
        {
          name: "未开始",
          num: 0,
          isSelect: false,
        },
        {
          name: "进行中",
          num: 0,
          isSelect: false,
        },
        {
          name: "已结束",
          num: 0,
          isSelect: false,
        },
      ],
      materialCheckPlanTableDataMsgPage: {
        pageIndex: 1,
        pageSize: 10,
        pageTotal: 0,
      },
      addOrEditTask: 1, // 1 新增 0 编辑 2 查看
      taskDialogVisible: false,
      taskForm: {
        planCode: "",
        taskType: 2,
        planName: "",
        startTime: "",
        endTime: "",
        timeVal: [], // 用于绑定日期
        schoolList: "",
        schoolYear: "",
        session: "",
        grade: "",
        inspectType: "",
      },
      rules: {
        planCode: [
          { required: true, message: "请输入任务编号", trigger: "blur" },
          { validator: checkPlanCode, trigger: "blur" },
        ],
        taskType: [
          { required: true, message: "请选择任务类型", trigger: "change" },
        ],
        planName: [
          { required: true, message: "请输入任务名称", trigger: "blur" },
        ],
        timeVal: [
          { required: true, message: "请选择任务日期", trigger: "change" },
        ],
        schoolYear: [
          { required: true, message: "请选择所属学年", trigger: "change" },
        ],
        session: [
          { required: true, message: "请选择所属学期", trigger: "change" },
        ],
        inspectType: [
          { required: true, message: "请选择巡查主题", trigger: "change" },
        ],
        grade: [{ required: true, message: "请选择年级", trigger: "change" }],
        schoolList: [
          { required: true, message: "请选择执行学校", trigger: "change" },
        ],
      },
      addSchoolDialogVisible: false,
      tempAddSchoolList: [],
      addSchoolList: [],
      schoolListQueryCondition: {
        sysOrgAreaId: 0,
        name: "",
      },
      schoolSelectionList: [],
      schoolIdListBeforeEdit: [],
      isSavingSchooldList: false,
      schoolTableDataList: [],
      planId: "",

      sysOrgAreaList: [],
    };
  },
  watch: {
    addSchoolDialogVisible: function (val) {
      if (!val && !this.isSavingSchooldList) {
        this.handleCancelAddSchoolBtnClick();
      } else {
        this.isSavingSchooldList = false;
      }
    },
  },
  created() {
    this.fetchSchoolInfo();
    this.fetchMaterialCheckTaskData();
    this.fetchPlanStatueData();
    this.fetchAreaSelectInfo();
  },
  methods: {
    // 获取实验仪器耗材盘点任务数据
    fetchMaterialCheckTaskData() {
      getCheckPlanListApi(
        this.materialCheckPlanTableDataMsgPage.pageIndex,
        this.materialCheckPlanTableDataMsgPage.pageSize,
        this.taskManageCondition
      ).then((res) => {
        if (res.success) {
          this.materialCheckPlanList = res.data;
          this.materialCheckPlanTableDataMsgPage.pageTotal = res.total;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    //顶部地区下拉框获取数据
    fetchAreaSelectInfo() {
      getAreaSite().then((res) => {
        let { code, data, msg } = res;
        if (code == 200) {
          // data.childrenOrg.unshift({ orgId: 0, orgName: '全部' })
          this.sysOrgAreaList = data.childrenOrg;
        } else {
          console.log("地区筛选下拉框数据获取失败");
        }
      });
    },
    // 获取学校信息
    fetchSchoolInfo() {
      getParamSchoolApi(this.schoolListQueryCondition).then((res) => {
        if (res.success) {
          this.schoolTableDataList = res.data;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 获取详情状态
    fetchPlanStatueData() {
      getCheckPlanStatusApi().then((res) => {
        if (res.success) {
          this.taskStatusList.forEach((item, i) => {
            if (i == 0) {
              item.num = res.data.totalCount;
            }
            if (i == 1) {
              item.num = res.data.unBeginCount;
            }
            if (i == 2) {
              item.num = res.data.processingCount;
            }
            if (i == 3) {
              item.num = res.data.endCount;
            }
          });
          this.$forceUpdate();
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 点击查看盘点任务完成情况
    handleCheckFinishSituation(row) {
      // 跳转至新页面
      this.$router.push({
        path: `/taskManage/checkPlan/checkPlanDetail?taskInfo=${JSON.stringify(
          row
        )}`,
      });
    },
    // 点击编辑任务
    handleEditTaskBtn(row, eventType) {
      this.planId = row.planId;
      getCheckPlanDetailApi(row.planId).then((res) => {
        if (res.success) {
          const { data } = res;
          this.addSchoolList = data.planSchoolRelationList;
          this.schoolIdListBeforeEdit = data.planSchoolRelationList.map(
            (item) => ({
              relationId: item.relationId,
              sysOrgSchoolId: item.sysOrgSchoolId,
            })
          );
          this.taskForm = {
            planCode: data.planCode,
            taskType: 2,
            planName: data.planName,
            startTime: data.startTime,
            endTime: data.endTime,
            timeVal: [new Date(data.startTime), new Date(data.endTime)], // 用于绑定日期
            schoolList: "",
            schoolYear: data.schoolYear,
            session: data.session,
            grade: data.grade,
            inspectType: data.inspectType,
            remark:data.mark,
          };
          this.fillSchoolFormItem(1);
          this.addOrEditTask = eventType;
          this.taskDialogVisible = true;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 点击新增任务
    handleAddTaskBtn() {
      this.addOrEditTask = 1;
      // 表格初始化
      this.taskForm = {
        planCode: this.getCodeFn("PD"),
        taskType: 2,
        planName: "",
        startTime: "",
        endTime: "",
        timeVal: [], // 用于绑定日期
        schoolList: "",
        schoolYear: "",
        session: "",
        grade: "",
        inspectType: "",
      };
      this.taskDialogVisible = true;
      this.$nextTick(() => {
        this.$refs.taskForm.clearValidate();
      });
    },
    //  新增或编辑任务集合保存，暂存按钮点击  1 暂存  2 保存发布
    handleSaveTaskBtnClick(btnType) {
      this.$refs.taskForm.validate((valid) => {
        if (valid) {
          this.handleConfirmAddOrEditCheckPlanFunc(btnType);
        }
      });
    },
    // 新增或编辑盘点计划
    handleConfirmAddOrEditCheckPlanFunc(btnType) {
      if (this.addOrEditTask === 1) {
        // 这里新增盘点计划
        let addSysOrgSchoolIdList = this.addSchoolList.map(
          (item) => item.sysOrgSchoolId
        );
        let startTime = this.taskForm.timeVal[0].getTime();
        let endTime = this.taskForm.timeVal[1].getTime();

        let addParams = {
          addSysOrgSchoolIdList,
          delSysOrgSchoolIdList: [],
          endTime,
          excuteStatus: +btnType,
          mark: this.taskForm.remark,
          planCode: this.taskForm.planCode,
          planName: this.taskForm.planName,
          startTime,
        };
        addCheckPlanApi(addParams).then((res) => {
          if (res.success) {
            this.$message.success("新增成功!");
            this.fetchMaterialCheckTaskData();
            this.taskDialogVisible = false;
          } else {
            this.$message.error(res.msg);
            return;
          }
        });
      } else {
        // 这里编辑盘点计划
        let addSysOrgSchoolIdList = [];
        let delSysOrgSchoolIdList = [];
        let nowSchoolIdList = this.addSchoolList.map(
          (item) => item.sysOrgSchoolId
        );
        let pastSchoolIdList = this.schoolIdListBeforeEdit.map(
          (item) => item.sysOrgSchoolId
        );
        pastSchoolIdList.forEach((item1, i) => {
          if (nowSchoolIdList.indexOf(item1) == -1) {
            delSysOrgSchoolIdList.push(
              this.schoolIdListBeforeEdit[i].relationId
            );
          }
        });
        nowSchoolIdList.forEach((item2) => {
          if (pastSchoolIdList.indexOf(item2) == -1) {
            addSysOrgSchoolIdList.push(item2);
          }
        });
        let startTime = this.taskForm.timeVal[0].getTime();
        let endTime = this.taskForm.timeVal[1].getTime();
        let editParams = {
          addSysOrgSchoolIdList,
          delSysOrgSchoolIdList,
          endTime,
          excuteStatus: +btnType,
          mark: this.taskForm.remark,
          planCode: this.taskForm.planCode,
          planName: this.taskForm.planName,
          startTime,
          planId: this.planId,
        };
        updateCheckPlanApi(editParams).then((res) => {
          if (res.success) {
            this.$message.success("修改成功!");
            this.fetchMaterialCheckTaskData();
            this.taskDialogVisible = false;
          } else {
            this.$message.error(res.msg);
            return;
          }
        });
      }
    },
    // 点击删除任务
    handleDeleteTaskBtn(row) {
      this.$confirm(`确定删除该任务吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // 删除操作
          deleteCheckPlanApi(row.planId).then((res) => {
            if (res.success) {
              this.$message.success("删除成功!");
              this.fetchMaterialCheckTaskData();
            } else {
              this.$message.error(res.msg);
              return;
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: `已取消删除`,
          });
        });
    },
    // 添加学校按钮
    handleAddSchoolBtnClick() {
      // 保存数据
      this.tempAddSchoolList = [];
      for (let i = 0; i < this.addSchoolList.length; i++) {
        let obj = {};
        for (const key in this.addSchoolList[i]) {
          obj[key] = this.addSchoolList[i][key];
        }
        this.tempAddSchoolList.push(obj);
      }
      this.addSchoolDialogVisible = true;
    },
    // 删除所有已添加学校按钮
    handleRemoveAllAddedSchoolBtnClick() {
      this.addSchoolList = [];
    },
    // 删除已添加学校按钮
    handleRemoveSelectSchoolBtn(item) {
      this.addSchoolList.splice(this.addSchoolList.indexOf(item), 1);
    },
    // 批量添加学校事件
    handleBatchAddBtnClick() {
      if (this.schoolSelectionList.length === 0) return;
      for (let i = 0; i < this.schoolSelectionList.length; i++) {
        let result = true;
        for (let j = 0; j < this.addSchoolList.length; j++) {
          if (this.schoolSelectionList[i].code === this.addSchoolList[j].code) {
            result = false;
          }
        }
        if (result) {
          this.addSchoolList.push(this.schoolSelectionList[i]);
        }
      }
      // 清空已选的选框
      this.$refs.schoolTableDataList.clearSelection();
    },
    // 取消添加执行学校
    handleCancelAddSchoolBtnClick() {
      this.addSchoolList = this.tempAddSchoolList;
      this.addSchoolDialogVisible = false;
    },
    // 保存添加执行学校
    handleSaveAddSchoolBtnClick() {
      this.isSavingSchooldList = true;
      // 填入表格数据
      this.fillSchoolFormItem();
      // console.log(this.addSchoolList.sysOrgSchoolId)
      this.addSchoolDialogVisible = false;
    },
    // 回填表单中执行学校一栏数据
    fillSchoolFormItem(val = 0) {
      this.taskForm.schoolList = "";
      this.addSchoolList?.forEach((item) => {
        if (!this.taskForm.schoolList) {
          let name = item.name ? item.name : item.sysOrgSchoolName;
          this.taskForm.schoolList += name;
        } else {
          let name = item.name ? item.name : item.sysOrgSchoolName;
          this.taskForm.schoolList += "，" + name;
        }
      });
    },
    // 表格选择事件
    handleSelectionChange(selection) {
      this.schoolSelectionList = selection;
    },
    // 调用该方法可以判断学校表格一栏是显示 添加 还是 已添加
    handleSchoolStatusCheck(row) {
      let result = false;
      if (this.addSchoolList) {
        this.addSchoolList.forEach((item) => {
          if (item.sysOrgSchoolId === row.sysOrgSchoolId) {
            result = true;
          }
        });
      }
      return result;
    },
    // 表格中添加学校按钮
    handleTableAddSchoolBtnClick(row) {
      this.addSchoolList.push(row);
    },
    // 任务管理日期选择
    queryTaskManageDialogDateChange(value) {
      if (value.length > 0) {
        this.taskForm.startTime = Date.parse(value[0]);
        this.taskForm.endTime = Date.parse(value[1]);
      }
    },
    // 查询任务数据
    handleQueryTaskTableData() {
      this.materialCheckPlanTableDataMsgPage.pageIndex = 1;
      this.materialCheckPlanTableDataMsgPage.pageSize = 10;
      this.fetchMaterialCheckTaskData();
    },
    // 重置任务数据
    handleResetTaskTableData() {
      this.taskManageCondition = {
        planCodeName: "",
        status: "",
        startTime: "",
        endTime: "",
      };
      this.queryTaskManageTime = "";
      this.handleSelectTaskStatus(0);
    },
    // 选择任务状态
    handleSelectTaskStatus(i) {
      this.taskStatusList.forEach((item) => {
        item.isSelect = false;
      });
      this.taskStatusList[i].isSelect = true;
      if (i == 0) {
        this.taskManageCondition.status = "";
      } else {
        this.taskManageCondition.status = i + 1;
      }
      this.handleQueryTaskTableData();
    },
    handleFormSelectTaskStatus(val) {
      this.handleSelectTaskStatus(val ? val - 1 : 0);
    },
    // 切换仪器耗材盘点表格页容量
    handleSizeChangeMaterialCheckPlanTableDataMsgPage(val) {
      this.materialCheckPlanTableDataMsgPage.pageIndex = 1;
      this.materialCheckPlanTableDataMsgPage.pageSize = val;
      this.fetchMaterialCheckTaskData();
    },
    // 切换仪器耗材盘点表格当前页
    handleCurrentChangeMaterialCheckPlanTableDataMsgPage(val) {
      this.materialCheckPlanTableDataMsgPage.pageIndex = val;
      this.fetchMaterialCheckTaskData();
    },
    // 任务日期选择
    queryTaskManageDateChange(value) {
      if (value.length > 0) {
        this.taskManageCondition.startTime = Date.parse(value[0]);
        this.taskManageCondition.endTime = Date.parse(value[1]);
      }
      this.handleQueryTaskTableData();
    },
    getCodeFn(keyWord){
      return `${keyWord}${formatDate(new Date(), "yyyyMMddhhmm")}`
    },
    // 格式化
    customFormatter(row, column) {
      if (isEmpty(row)) {
        return;
      }
      switch (column.property) {
        case "startTime":
          return formatDate(new Date(row.startTime), "yyyy-MM-dd");
        case "endTime":
          return formatDate(new Date(row.endTime), "yyyy-MM-dd");
        case "excuteStatus":
          switch (row.excuteStatus) {
            case 0:
              return "草稿";
            case 2:
              return "未开始";
            case 3:
              return "进行中";
            case 4:
              return "已结束";
          }
      }
    },
    handleQuerySchoolBtnClick() {},
    handleResetSchoolBtnClick() {
      this.schoolListQueryCondition = {
        sysOrgAreaId: "",
        name: "",
      };
      this.fetchSchoolInfo();
    },
    // 格式化学校列表
    handleSchoolTextFun(schoolList) {
      let result = "";
      schoolList.forEach((item) => {
        if (!result) {
          result += item.sysOrgSchoolName;
        } else {
          result += ", " + item.sysOrgSchoolName;
        }
      });
      if (result.length >= 20) {
        result = result.substring(0, 20) + "...";
      }
      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
.taskStatusItem {
  width: 122px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  cursor: pointer;
  border: 1px solid #ccc;
  span {
    font-size: 14px;
  }
  &.selected {
    border-color: rgb(84, 173, 255);
    color: rgb(84, 173, 255);
  }
}
.taskStatusItem:nth-child(1) {
  border-radius: 5px 0 0 5px;
}
.taskStatusItem:nth-child(6) {
  border-radius: 0 5px 5px 0;
}
.blueBtn {
  padding-right: 10px;
  margin-right: 10px;
  border-right: 1px solid #00f;
  color: #00f;
  text-decoration: underline;
  cursor: pointer;
}
.blueBtn1 {
  color: #00f;
  text-decoration: underline;
  cursor: pointer;
}
.redBtn {
  color: rgb(217, 0, 27);
  text-decoration: underline;
  cursor: pointer;
}
::v-deep .taskForm {
  margin-left: 20px;
  .el-form-item__error {
    margin-left: 105px;
  }
}
::v-deep .schoolList {
  .el-textarea.is-disabled .el-textarea__inner {
    background-color: #fff;
    cursor: auto;
    color: #606266;
  }
  &.needColorDeep {
    .el-textarea.is-disabled .el-textarea__inner {
      background-color: #f5f7fa;
      cursor: not-allowed;
      color: #c0c4cc;
    }
  }
}

::v-deep .schoolList {
  &.el-form-item.is-error.is-required {
    .el-textarea.is-disabled .el-textarea__inner {
      border-color: #f56c6c;
    }
  }
}
.selectText {
  position: absolute;
  right: 60px;
  top: 25px;
  height: 18px;
  line-height: 18px;
  font-size: 16px;
  color: #00f;
  text-decoration: underline;
  cursor: pointer;
}
.tag-title {
  padding-left: 10px;
  border-left: 1px solid #ccc;
  font-size: 16px;
  height: 16px;
  line-height: 16px;
  margin-bottom: 25px;
}
.tag-content {
  margin-bottom: 25px;
  width: 95%;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 0 15px 10px 15px;
}
.tag-content-item {
  padding: 0 10px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 5px 0 0 5px;
  position: relative;
  display: inline-block;
  margin-right: 40px;
  margin-top: 10px;
}
.tag-item-x {
  position: absolute;
  right: -22px;
  top: -1px;
  height: 35px;
  width: 20px;
  line-height: 35px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
}
.tableTitleBox {
  font-size: 20px;
  margin-top: 20px;
  margin-left: 20px;
  padding-left: 10px;
  border-left: 8px solid rgb(64, 158, 255);
}
</style>
