// 仪器耗材盘点计划模块
import { request, noTimeOutReq } from "@/utils/request.js";

// 新增【盘点计划数据】
export function addCheckPlanApi(data) {
  return request({
    url: `/eqpt/stock/inventory/plan/add`,
    method: "post",
    data,
  });
}

// 删除【盘点计划数据】
export function deleteCheckPlanApi(planId) {
  return request({
    url: `/eqpt/stock/inventory/plan/delete`,
    method: "post",
    params: {
      planId,
    },
  });
}

// 获取【盘点计划数据】详情
export function getCheckPlanDetailApi(planId) {
  return request({
    url: `/eqpt/stock/inventory/plan/get`,
    method: "post",
    params: {
      planId,
    },
  });
}

// 分页展示所有【盘点计划信息】
export function getCheckPlanListApi(pageIndex, pageSize, data) {
  return request({
    url: `/eqpt/stock/inventory/plan/page/${pageIndex}/${pageSize}/list`,
    method: "post",
    data,
  });
}

// 当前计划【盘点任务数据】
export function getCurCheckPlanDataApi(planId, status) {
  return request({
    url: `/eqpt/stock/inventory/plan/task/list`,
    method: "post",
    params: {
      planId,
      status
    },
  });
}

// 修改【草稿盘点计划数据】
export function updateCheckPlanApi(data) {
  return request({
    url: `/eqpt/stock/inventory/plan/update`,
    method: "post",
    data,
  });
}

// 盘点计划状态统计
export function getCheckPlanStatusApi() {
  return request({
    url: `/eqpt/stock/inventory/plan/status/count`,
    method: "post",
  });
}

//盘点任务状态统计
export function getCheckPlanSummitStatusApi(planId) {
  return request({
    url: `/eqpt/stock/inventory/task/status/count/${planId}`,
    method: "post",
  });
}